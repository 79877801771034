
<script setup lang='ts'>
import { computed, ref, onMounted } from 'vue';
import { NLayout, NLayoutContent, NModal, NTabPane, NTabs } from 'naive-ui'
import { 
  // useRouter,
  useRoute } from 'vue-router'
import Sider from './sider/index.vue'
import Footer from '../components/footer/inde.vue'
import WxCode from '@/components/wx-code/index.vue'
import login from './login.vue'
import register from './register.vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore, useAuthStore, useUserStore } from '@/store'
const route = useRoute()
const appStore = useAppStore()
const authStore = useAuthStore()
const userStore = useUserStore()

const { isMobile } = useBasicLayout()

const collapsed = computed(() => appStore.siderCollapsed)

const isShow = ref(false);

const needPermission = computed(() => !authStore.token)

// 获取屏幕尺寸适配移动端
const getMobileClass = computed(() => {
  if (isMobile.value)
    return ['rounded-none', 'shadow-none']
  return ['border', 'rounded-md', 'shadow-md', 'dark:border-neutral-800', 'mobile-slider']
})

const getContainerClass = computed(() => {
  return [
    'h-full',
    { 'pl-': !isMobile.value && !collapsed.value },
  ]
})

const changeLogin = () => {
  isShow.value = true;
}

const loginSuccess = () => {
  isShow.value = false;
}
const activeTabIndex = ref('login')

const regSuccess = () => {
  activeTabIndex.value = 'login'
}

onMounted(() => {

})

// 页面加载请求用户信息
if (!needPermission.value) {
  userStore.getUserData()
  authStore.getMenuList(route)
}
 
</script>

<template>
  <div class="h-full dark:bg-[#24272e] transition-all" :class="[isMobile ? 'p-0' : 'p-4']">
    <div class="h-full overflow-hidden" :class="getMobileClass">
      <NLayout class="z-40 transition" :class="getContainerClass" has-sider>
        <Sider v-if="route.name === 'Chat'" />
        <NLayoutContent class="h-full">
          <RouterView v-slot="{ Component, route }">
            <component :is="Component" :key="route.fullPath" @change-login="changeLogin" />
          </RouterView>
          <slot></slot>
        </NLayoutContent>
        <Footer />
      </NLayout>
    </div>
    <NModal :show="isShow" style="width: 90%; max-width: 640px">
      <div class="p-10 bg-white rounded dark:bg-slate-800">
        <div class="space-y-4">
          <NTabs default-value="login" size="large" animated v-model:value="activeTabIndex">
            <NTabPane name="login" tab="登录">
              <login @login-success="loginSuccess" />
            </NTabPane>
            <NTabPane name="register" tab="注册">
              <register @reg-success="regSuccess" />
            </NTabPane>
          </NTabs>
        </div>
      </div>
    </NModal>
    <WxCode />
</div></template>
