
import { defineStore } from 'pinia'
import { getLocalState } from './helper'

export const useChatStore = defineStore('chat-store', {
  state: (): Chat.ChatState => getLocalState(),
  getters: {
    getChatHistoryByCurrentActive() {
    },

    getChatByUuid() {
    },
  },

  actions: {
    setUsingContext() {
    },

    addHistory() {

    },

    updateHistory() {
    },

    async deleteHistory() {
    },

    async setActive() {

    },

    getChatByUuidAndIndex() {
    },

    addChatByUuid() {

    },
    getChatByUuid() {

    },

    updateChatByUuid() {
    },

    updateChatSomeByUuid() {
    },

    deleteChatByUuid() {
    },

    clearChatByUuid() {
    },

    async reloadRoute() {
    },

    recordState() {

    },
  },
})
