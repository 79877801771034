/*
 * @Author: mjjh
 * @LastEditTime: 2024-12-12 17:10:42
 * @FilePath: \chagpt-shuowen\src\api\index.ts
 * @Description:
 */
import type { AxiosProgressEvent, GenericAbortSignal } from 'axios'
import { post, get } from '@/utils/request'

export function fetchChatAPI<T = any>(
  prompt: string,
  options?: { conversationId?: string; parentMessageId?: string },
  signal?: GenericAbortSignal,
) {
  return post<T>({
    url: '/chat',
    data: { prompt, options },
    signal,
  })
}

export function fetchChatAPIProcess<T = any>(
  params: {
    prompt: string
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal,
  
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void },
) {
  const data: Record<string, any> = {
    prompt: params.prompt,
    options: params.options,
    model:  window.location.href.indexOf('gptId') > -1 ? window.gpts : window.gptModel,
    modelType:  window.modelType,
    gptid: window.gptid,
    modelZhName: window.modelName
  }

  return post<T>({
    url: '/chat_message/send',
    data,
    signal: params.signal,
    onDownloadProgress: params.onDownloadProgress,
  })
}

export function fetchChatPdfAPIProcess<T = any>(
  params: {
    prompt: string
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void },
) {
  const data: Record<string, any> = {
    prompt: params.prompt,
    options: params.options,
    pdfId: params.pdfId,
    ...params
  }

  return post<T>({
    url: '/userpdf/send',
    data,
    signal: params.signal,
    onDownloadProgress: params.onDownloadProgress,
  })
}
/**
 * @description: 获取PDF列表
 * @return {*}
 */
export function getPdfList<T>(data) {
  return post<T>({
    url: '/userpdf/list',
    data
  })
}

/**
 * @description: 获取pdfurl
 * @return {*}
 */
export function getPdfUrl<T>(data) {
  return post<T>({
    url: '/userpdf/list',
    data
  })
}
/**
 * @description: 获取PDF消息列表
 * @return {*}
 */
export function getPdfMessageList<T>(data) {
  return post<T>({
    url: '/userpdf/messageList',
    data
  })
}
/**
 * @description: 获取PDF大纲
 * @return {*}
 */
export function getPdfOutline<T>(data) {
  return post<T>({
    url: '/userpdf/getPdfOutline',
    data
  })
}

/**
 * @description: 获取聊天室
 * @return {*}
 */
export function getChatRoom<T>(data) {
  return post<T>({
    url: '/chat_message/roomList',
    data
  })
}

/**
 * @description: 获取聊天室
 * @return {*}
 */
export function getChatMessage<T>(data) {
  return post<T>({
    url: '/chat_message/messageList',
    data
  })
}

/**
 * @description: 获取PDF翻译
 * @return {*}
 */
export function getPdfTranslate<T>(data) {
  return post<T>({
    url: '/userpdf/getPdfToTranslate',
    data
  })
}