<script setup lang='ts'>
import { ref, onBeforeMount, computed } from 'vue'
import { 
  NInput,
  // NPopconfirm,
  NScrollbar, NButton } from 'naive-ui'
  import { useRouter, useRoute } from 'vue-router';
  const router = useRouter()
  const route = useRoute()
import { getChatRoom } from '@/api';
import {
  useAppStore,
} from '@/store'
const appStore = useAppStore()

const collapsed = computed(() => appStore.siderCollapsed)

function handleUpdateCollapsed() {
  appStore.setSiderCollapsed(!collapsed.value)
}
import { SvgIcon } from '@/components/index'

const dataSources = ref([])
const currentId = ref(null);

interface Emit {
  (e: 'click'): void
}

const emit = defineEmits<Emit>();

const handleAdd = (isAdd) => {
  const id = (+new Date());
  dataSources.value.unshift({
    id: +new Date(),
    title: '新建聊天',
    id
  })
  router.push({
    path: '/uuid/' + id,
    query: {
      ...(route.query.isAddChat ? route.query : {}),
      currentTitle: '新建聊天',
    }
  })

  currentId.value = id;
  if(!isAdd) {
    handleUpdateCollapsed()
  }
 
}

const fetchRoms = async () => {
  const { data } = await getChatRoom({ size: 10000 })
  dataSources.value = data.records;

  if(data.records.length === 0 || route.query.isAddChat) {
    handleAdd(true)
  } else {
    currentId.value = dataSources.value?.[0]?.id;
    const currentData = dataSources.value.find(item => item.id === currentId.value)
    router.push({
      path: '/uuid/' + currentId.value,
      query: {
        currentTitle: currentData?.title,
        gptId: currentData.gptsId,
        gpts: currentData.gptsModelName,
        modelName: currentData.gptsModelZhName,
      }
    })
  }
}

/**
 * @description: 选中对应的聊天室
 * @param {*} uuid
 * @return {*}
 */
async function handleSelect(data: Chat.History) {
  currentId.value = data.id
  // if (isActive(uuid))
  //   return
  // if (chatStore.active)
  //   chatStore.updateHistory(chatStore.active, { isEdit: false })
  // const query = chatStore.chat?.find(item => item.uuid === uuid)?.query ??{}
  // await chatStore.setActive(uuid,query)

  // if (isMobile.value)
  //   appStore.setSiderCollapsed(true)
  router.push({
    path: '/uuid/' + data.id,
    query: {
      currentTitle: dataSources.value.find(item => item.id === data.id)?.title,
      gptId: data.gptsId,
      gpts: data.gptsModelName,
      modelName: data.gptsModelZhName,
    }
  })

  
  handleUpdateCollapsed()
}

onBeforeMount(fetchRoms)

/**
 * @description: 编辑聊天室名称
 * @param {*} uuid 聊天室id
 * @param {*} isEdit 是否编辑聊天室
 * @param {*} event 对应listItem的数据
 * @return {*}
 */
// function handleEdit({ uuid }: Chat.History, isEdit: boolean, event?: MouseEvent) {
//   event?.stopPropagation()
//   chatStore.updateHistory(uuid, { isEdit })
// }

/**
 * @description: 删除聊天室
 * @param {*} index
 * @param {*} event 对应listItem的数据
 * @return {*}
 */
// function handleDelete(index: number, event?: MouseEvent | TouchEvent) {
//   event?.stopPropagation()
//   chatStore.deleteHistory(index)
//   if (isMobile.value)
//     appStore.setSiderCollapsed(true)
// }
// 按钮防抖
// const handleDeleteDebounce = debounce(handleDelete, 600)

/**
 * @description: 键盘Enter事件 逻辑同handleEdit
 * @param {*} uuid
 * @param {*} isEdit
 * @param {*} event
 * @return {*}
 */
function handleEnter({ id }: Chat.History, isEdit: boolean, event: KeyboardEvent) {
  event?.stopPropagation()
  if (event.key === 'Enter') {
    currentId.value = id
  }
    // chatStore.updateHistory(uuid, { isEdit })
}

/**
 * @description: 给active赋值当前选中的item的uuid
 * @param {*} uuid
 * @return {*}
 */
function isActive(uuid: number) {
  return currentId.value === uuid
}

</script>

<template>
<main class="flex flex-col flex-1 min-h-0">
  <div class="p-4">
          <NButton dashed block @click="handleAdd">
            {{ $t('chat.newChatButton') }}
          </NButton>
        </div>
<div class="flex-1 min-h-0 pb-4 overflow-hidden">
  <NScrollbar class="px-4">
    <div class="flex flex-col gap-2 text-sm">
      <template v-if="!dataSources.length">
        <div class="flex flex-col items-center mt-4 text-center text-neutral-300">
          <SvgIcon icon="ri:inbox-line" class="mb-2 text-3xl" />
          <span>{{ $t('common.noData') }}</span>
        </div>
      </template>
      <template v-else>
        <div v-for="(item, index) of dataSources" :key="index">
          <a
            class="relative flex items-center gap-3 px-3 py-3 break-all border rounded-md cursor-pointer hover:bg-neutral-100 group dark:border-neutral-800 dark:hover:bg-[#24272e]"
            :class="isActive(item.id) && ['border-[#FFB814]', 'bg-neutral-100', 'text-[#FFB814]', 'dark:bg-[#24272e]', 'dark:border-[#FFB814]', 'pr-14']"
            @click="handleSelect(item)"
          >
            <span>
              <SvgIcon icon="ri:message-3-line" />
            </span>
            <div class="relative flex-1 overflow-hidden break-all text-ellipsis whitespace-nowrap">
              <NInput
                v-if="item.isEdit"
                v-model:value="item.title" size="tiny"
                @keypress="handleEnter(item, false, $event)"
              />
              <span v-else>{{ item.title }}</span>
            </div>
            <div v-if="isActive(item.uuid)" class="absolute z-10 flex visible right-1">
              <template v-if="item.isEdit">
                <!-- <button class="p-1" @click="handleEdit(item, false, $event)">
                  <SvgIcon icon="ri:save-line" />
                </button> -->
              </template>
              <template v-else>
                <!-- <button class="p-1">
                  <SvgIcon icon="ri:edit-line" @click="handleEdit(item, true, $event)" />
                </button> -->
                <!-- <NPopconfirm placement="bottom" @positive-click="handleDeleteDebounce(index, $event)">
                  <template #trigger>
                    <button class="p-1">
                      <SvgIcon icon="ri:delete-bin-line" />
                    </button>
                  </template>
                  {{ $t('chat.deleteHistoryConfirm') }}
                </NPopconfirm> -->
              </template>
            </div>
          </a>
        </div>
      </template>
    </div>
  </NScrollbar>
  </div>
</main>
</template>
